import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import { FixedSizeList as List } from "react-window";
import "./../App.css";
import { usePlan } from "../contexts/PlanContext";
import { countries2, customStyles, categories2, capitalize } from "./constants";
import ClipLoader from "react-spinners/ClipLoader";
import FiltersModal from "./FilterModal";
import JobRow from "./JobRow";
import StickySignInPrompt from "./StickyDivSignInPrompt";
import { postedOnOptions, salaryOptions } from "./constants/index";
import FiltersForm from "./FiltersForm";

const CACHE_EXPIRATION_TIME = 2000000;

const JobList = ({ isAuthenticated }) => {
  const {
    serverUrl,
    setJobsFetched,
    visibleBlurredJobs,
    prefilteredJobs,
    jobsFetched,
  } = usePlan();

  const baseUrl = serverUrl;
  // const baseUrl = "http://localhost:5000";
  const [jobs, setJobs] = useState(jobsFetched || []);
  const [name, setName] = useState("");
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(
    !jobsFetched || jobsFetched.length === 0
  );
  const [postedOn, setPostedOn] = useState(null);
  const [showStickyDiv, setShowStickyDiv] = useState(false);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const rowHeight = 270;
  const hideThreshold = rowHeight * 25;
  const [technologies, setTechnologies] = useState([]);
  const [selectedTechnologyOptions, setSelectedTechnologyOptions] = useState(
    []
  );

  const timestamp = new Date().getTime();

  useEffect(() => {
    setCountries(countries2);
    setCategories(categories2);
    setTechnologies(() => {
      const techFromJobs = jobs.map((job) => job.stack || []);
      const uniqueTechFromJobs = new Set(techFromJobs.flat());
      return Array.from(uniqueTechFromJobs);
    });
  }, [jobs]);

  useEffect(() => {
    const removeFullInnerText = (jobs) => {
      return jobs.map((job) => {
        const { full_inner_text, ...rest } = job;
        return rest;
      });
    };

    const fetchFilteredJobs = async () => {
      setLoading(true);
      if (prefilteredJobs) {
        const sanitizedJobs = removeFullInnerText(prefilteredJobs);
        setJobs(sanitizedJobs);
      }
      setLoading(false);
    };

    const fetchInitialJobs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/jobs?timestamp=${timestamp}`
        );
        const initialJobs = response.data.reverse();
        const sortedJobs = initialJobs.sort((a, b) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          return dateB - dateA;
        });
        const sanitizedJobs = removeFullInnerText(sortedJobs);
        setJobs(sanitizedJobs);
      } catch (error) {
        console.error("Error fetching initial jobs:", error);
      }
      setLoading(false);
    };

    const fetchRemainingJobs = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/jobs/remaining?timestamp=${timestamp}`
        );
        const remainingJobs = response.data.reverse();
        const sanitizedJobs = removeFullInnerText(remainingJobs);

        setJobs((prevJobs) => {
          const allJobs = [...prevJobs, ...sanitizedJobs];
          const uniqueJobs = Array.from(
            new Set(allJobs.map((job) => job._id))
          ).map((id) => allJobs.find((job) => job._id === id));
          return uniqueJobs;
        });
      } catch (error) {
        console.error("Error fetching remaining jobs:", error);
      }
    };

    const fetchInitialAndRemainingJobs = async () => {
      await fetchInitialJobs();
      await fetchRemainingJobs();
    };

    if (prefilteredJobs && prefilteredJobs.length > 0) {
      fetchFilteredJobs();
    } else {
      fetchInitialAndRemainingJobs();
      const intervalId = setInterval(() => {
        fetchInitialAndRemainingJobs();
      }, CACHE_EXPIRATION_TIME);
      return () => clearInterval(intervalId);
    }
  }, [baseUrl, prefilteredJobs]);

  useEffect(() => {
    setJobsFetched(jobs);
  }, [jobs, setJobsFetched]);

  const debouncedSetName = debounce((value) => setName(value), 300);

  const handleNameChange = (event) => debouncedSetName(event.target.value);

  // Implemented filtering functions
  const handleTechnologyChange = (selectedOptions) => {
    setSelectedTechnologyOptions(selectedOptions || []);
  };

  const handleCountryChange = (selectedOptions) => {
    setSelectedCountryOptions(selectedOptions || []);
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategoryOptions(selectedOptions || []);
  };

  const handlePostedOnChange = (selectedOption) => {
    setPostedOn(selectedOption);
  };

  const handleSalaryRangeChange = (selectedOption) => {
    setSelectedSalaryRange(selectedOption);
  };

  const handleRemoveFilter = (type, value) => {
    if (type === "name") {
      setName("");
    } else if (type === "technology") {
      setSelectedTechnologyOptions((prev) =>
        prev.filter((opt) => opt.value !== value)
      );
    } else if (type === "country") {
      setSelectedCountryOptions((prev) =>
        prev.filter((opt) => opt.value !== value)
      );
    } else if (type === "category") {
      setSelectedCategoryOptions((prev) =>
        prev.filter((opt) => opt.value !== value)
      );
    } else if (type === "postedOn") {
      setPostedOn(null);
    } else if (type === "salaryRange") {
      setSelectedSalaryRange(null);
    }
  };

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        value: country,
        label: country,
      })),
    [countries]
  );

  const categoryOptions = useMemo(
    () =>
      categories.map((category) => ({
        value: category,
        label: category,
      })),
    [categories]
  );

  // Combined filters for display
  const combinedFilters = useMemo(() => {
    const filters = [];

    if (name) {
      filters.push({ type: "name", value: name, label: `Name: ${name}` });
    }

    selectedTechnologyOptions.forEach((option) => {
      filters.push({
        type: "technology",
        value: option.value,
        label: option.label,
      });
    });

    selectedCountryOptions.forEach((option) => {
      filters.push({
        type: "country",
        value: option.value,
        label: option.label,
      });
    });

    selectedCategoryOptions.forEach((option) => {
      filters.push({
        type: "category",
        value: option.value,
        label: option.label,
      });
    });

    if (postedOn) {
      filters.push({
        type: "postedOn",
        value: postedOn.value,
        label: postedOn.label,
      });
    }

    if (selectedSalaryRange) {
      filters.push({
        type: "salaryRange",
        value: selectedSalaryRange.value,
        label: selectedSalaryRange.label,
      });
    }

    return filters;
  }, [
    name,
    selectedTechnologyOptions,
    selectedCountryOptions,
    selectedCategoryOptions,
    postedOn,
    selectedSalaryRange,
  ]);

  // Filtering logic
  const finalFilteredJobs = useMemo(() => {
    let filteredJobs = jobs;

    if (name) {
      filteredJobs = filteredJobs.filter(
        (job) =>
          job.job_name &&
          job.job_name.toLowerCase().includes(name.toLowerCase())
      );
    }

    if (selectedTechnologyOptions.length > 0) {
      const selectedTechs = selectedTechnologyOptions.map((opt) =>
        opt.value.toLowerCase()
      );
      filteredJobs = filteredJobs.filter((job) => {
        if (!job.stack || !Array.isArray(job.stack)) return false;
        const jobStackLower = job.stack.map((tech) => tech.toLowerCase());
        return selectedTechs.some((tech) => jobStackLower.includes(tech));
      });
    }

    if (selectedCountryOptions.length > 0) {
      const selectedCountries = selectedCountryOptions.map((opt) =>
        opt.value.toLowerCase()
      );
      filteredJobs = filteredJobs.filter(
        (job) =>
          job.country && selectedCountries.includes(job.country.toLowerCase())
      );
    }

    if (selectedCategoryOptions.length > 0) {
      const selectedCategories = selectedCategoryOptions.map((opt) =>
        opt.value.toLowerCase()
      );
      filteredJobs = filteredJobs.filter(
        (job) =>
          job.category &&
          selectedCategories.includes(job.category.toLowerCase())
      );
    }

    if (postedOn) {
      const now = new Date();
      filteredJobs = filteredJobs.filter((job) => {
        const jobDate = new Date(job.timestamp || job.created_at);
        if (!jobDate) return false;

        const diffInDays = (now - jobDate) / (1000 * 3600 * 24);

        switch (postedOn.value) {
          case "today":
            return isSameDay(jobDate, now);
          case "last_7_days":
            return diffInDays < 7;
          case "last_30_days":
            return diffInDays < 30;
          case "last_3_months":
            return diffInDays < 90;
          default:
            return true;
        }
      });
    }

    if (selectedSalaryRange) {
      filteredJobs = filteredJobs.filter((job) => {
        const { min_salary, max_salary } = job;

        // Manejar "No salary information"
        if (selectedSalaryRange.value === null) {
          // Mostrar solo trabajos con `min_salary` y `max_salary` como `null`
          return min_salary === null && max_salary === null;
        }

        // Excluir trabajos sin información salarial
        if (min_salary === null || max_salary === null) {
          return false;
        }

        // Convertir salario anual a mensual si es necesario
        const minMonthlySalary = min_salary / 12;
        const maxMonthlySalary = max_salary / 12;

        let selectedMin, selectedMax;

        switch (selectedSalaryRange.value) {
          case "less_1000":
            selectedMin = 0;
            selectedMax = 1000;
            break;
          case "1000_2000":
            selectedMin = 1000;
            selectedMax = 2000;
            break;
          case "2000_3000":
            selectedMin = 2000;
            selectedMax = 3000;
            break;
          case "3000_4000":
            selectedMin = 3000;
            selectedMax = 4000;
            break;
          case "4000_5000":
            selectedMin = 4000;
            selectedMax = 5000;
            break;
          case "more_5000":
            selectedMin = 5000;
            selectedMax = Infinity;
            break;
          default:
            return true;
        }

        // Verificar si hay solapamiento entre los rangos
        return (
          minMonthlySalary <= selectedMax && maxMonthlySalary >= selectedMin
        );
      });
    }

    return filteredJobs;
  }, [
    jobs,
    name,
    selectedTechnologyOptions,
    selectedCountryOptions,
    selectedCategoryOptions,
    postedOn,
    selectedSalaryRange,
  ]);

  const containerHeight = useMemo(() => {
    const calculatedHeight = finalFilteredJobs.length * rowHeight;
    const availableHeight = window.innerHeight;
    return calculatedHeight > availableHeight
      ? availableHeight
      : calculatedHeight;
  }, [finalFilteredJobs.length]);

  const handleScroll = ({ scrollOffset }) => {
    // Ocultar sticky div si estamos dentro del threshold
    if (scrollOffset <= hideThreshold) {
      setShowStickyDiv(false);
    }

    // Desplazamiento programático hacia el final del documento
    if (scrollOffset > rowHeight) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }

    // Desplazamiento programático hacia el inicio del documento
    if (scrollOffset === 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (visibleBlurredJobs > 0) {
      setShowStickyDiv(true);
    }
  }, [visibleBlurredJobs]);

  return (
    <main className="jobList--main flex flex-col md:items-center justify-center mb-6">
      <Helmet>
        <title>AI Jobs - Find Your Next Job in AI and Tech.</title>
        <meta
          name="description"
          content="Browse the latest job openings in AI and tech. Sign up to see exclusive jobs and apply now."
        />
      </Helmet>

      <main className="jobList--main">
        {/* Botón de filtros para móviles */}
        <button
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-4 rounded-full lg:hidden"
          onClick={() => setIsFiltersOpen(true)}
        >
          Filters
        </button>
        <FiltersForm
          handleNameChange={handleNameChange}
          handleTechnologyChange={handleTechnologyChange}
          handleCountryChange={handleCountryChange}
          handleCategoryChange={handleCategoryChange}
          handlePostedOnChange={handlePostedOnChange}
          handleSalaryRangeChange={handleSalaryRangeChange}
          selectedTechnologyOptions={selectedTechnologyOptions}
          selectedCountryOptions={selectedCountryOptions}
          selectedCategoryOptions={selectedCategoryOptions}
          selectedSalaryRange={selectedSalaryRange}
          countryOptions={countryOptions}
          categoryOptions={categoryOptions}
          technologies={technologies}
        />
        {/* Modal de filtros para móviles */}
        {isFiltersOpen && (
          <FiltersModal
            onClose={() => setIsFiltersOpen(false)}
            handleCountryChange={handleCountryChange}
            handleCategoryChange={handleCategoryChange}
            handleTechnologyChange={handleTechnologyChange}
            technologies={technologies}
            customStyles={customStyles}
            selectedTechnologyOptions={selectedTechnologyOptions}
            countryOptions={countryOptions}
            selectedCountryOptions={selectedCountryOptions}
            categoryOptions={categoryOptions}
            selectedCategoryOptions={selectedCategoryOptions}
            postedOnOptions={postedOnOptions}
            salaryOptions={salaryOptions}
            postedOn={postedOn}
            handlePostedOnChange={handlePostedOnChange}
            selectedSalaryRange={selectedSalaryRange}
            handleSalaryRangeChange={handleSalaryRangeChange}
          />
        )}

        <section className="selected-filters text-white text-center rounded-xl p-4 mb-1">
          {combinedFilters.length > 0 ? (
            <div>
              <ul className="flex flex-wrap justify-center">
                {combinedFilters.map((filter, index) => (
                  <li
                    key={`${filter.type}-${filter.value}-${index}`}
                    className="flex items-center rounded-full bg-gray-900 px-4 py-2 text-[15px] w-fit relative ellipsis m-1"
                  >
                    <span className="mr-2">
                      {capitalize(filter.label) || capitalize(filter.value)}
                    </span>{" "}
                    <button
                      onClick={() =>
                        handleRemoveFilter(
                          filter.type,
                          filter.value,
                          filter.label
                        )
                      }
                      aria-label={`Remove filter: ${
                        filter.label || filter.value
                      }`}
                      className="font-bold text-xs cursor-pointer rounded-full"
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
              <p className="mt-8">{finalFilteredJobs.length} jobs found</p>
            </div>
          ) : (
            !loading && <p className="text-gray-300">Select some filters...</p>
          )}
        </section>

        <section className="w-[95%] md:w-full m-auto">
          {loading ? (
            <div className="flex items-center justify-center">
              <ClipLoader color={"#ffffff"} loading={loading} size={150} />
            </div>
          ) : (
            <ul className="custom-scroll-container hide-scrollbar">
              <List
                height={containerHeight}
                itemCount={finalFilteredJobs.length}
                itemSize={rowHeight}
                width={"100%"}
                onScroll={handleScroll}
              >
                {({ index, style }) => (
                  <JobRow
                    index={index}
                    style={style}
                    finalFilteredJobs={finalFilteredJobs}
                    isAuthenticated={isAuthenticated}
                  />
                )}
              </List>
            </ul>
          )}
        </section>

        {showStickyDiv && !isAuthenticated && <StickySignInPrompt />}
      </main>
    </main>
  );
};

export default JobList;

// Helper function
function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}
