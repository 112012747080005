import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
import CookieConsent from "./components/CookieConsent";
import { PlanProvider } from "./contexts/PlanContext";

// Patch ResizeObserver to prevent loop errors
if (typeof ResizeObserver !== "undefined") {
  const originalRO = ResizeObserver.prototype.observe;
  ResizeObserver.prototype.observe = function () {
    try {
      return originalRO.apply(this, arguments);
    } catch (e) {
      console.error(e);
    }
  };
}

window.addConsentListener = (callback) => {
  // Escucha cambios en el localStorage para la clave 'cookieConsent'
  window.addEventListener("storage", (event) => {
    if (event.key === "cookieConsent") {
      const consentValue = event.newValue;
      const consentState = {
        adConsentGranted: consentValue === "accepted",
        adUserDataConsentGranted: consentValue === "accepted",
        adPersonalizationConsentGranted: consentValue === "accepted",
        analyticsConsentGranted: consentValue === "accepted",
        functionalityConsentGranted: consentValue === "accepted",
        personalizationConsentGranted: consentValue === "accepted",
        securityConsentGranted: consentValue === "accepted",
      };
      callback(consentState);
    }
  });
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PlanProvider>
      <App />
      <CookieConsent />
    </PlanProvider>
  </React.StrictMode>
);

reportWebVitals();
