import React, { useEffect } from "react";
import SvgRightArrow from "./SvgRIghtArrow";
import { Link } from "react-router-dom";
import { usePlan } from "../contexts/PlanContext";
import axios from "axios";

const Home = () => {
  const { serverUrl, setJobsFetched, jobsFetched } = usePlan();
  const baseUrl = serverUrl;

  const removeFullInnerText = (jobs) => {
    return jobs.map((job) => {
      const { full_inner_text, ...rest } = job;
      return rest;
    });
  };

  useEffect(() => {
    if (!jobsFetched || jobsFetched.length === 0) {
      const fetchInitialJobs = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/jobs`);
          const initialJobs = response.data.reverse();
          const sortedJobs = initialJobs.sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return dateB - dateA;
          });
          const sanitizedJobs = removeFullInnerText(sortedJobs);
          setJobsFetched(sanitizedJobs);
        } catch (error) {
          console.error("Error fetching initial jobs:", error);
        }
      };

      const fetchRemainingJobs = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/jobs/remaining`);
          const remainingJobs = response.data.reverse();
          const sanitizedJobs = removeFullInnerText(remainingJobs);

          setJobsFetched((prevJobs) => {
            const allJobs = [...prevJobs, ...sanitizedJobs];
            const uniqueJobs = Array.from(
              new Set(allJobs.map((job) => job._id))
            ).map((id) => allJobs.find((job) => job._id === id));
            return uniqueJobs;
          });
        } catch (error) {
          console.error("Error fetching remaining jobs:", error);
        }
      };

      const fetchInitialAndRemainingJobs = async () => {
        await fetchInitialJobs();
        await fetchRemainingJobs();
      };

      fetchInitialAndRemainingJobs();
    }
  }, [baseUrl, setJobsFetched, jobsFetched]);
  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center mt-15 mx-16">
      <main className="flex flex-col items-center text-justify lg:mt-16 gap-10">
        <div className="flex items-center w-full margin-auto home__content lg:gap-8">
          <p className="text-xl text-gray-400 mt-4 max-w-md text-justify tracking-tighter home-description">
            It’s simple! Upload your resume and receive tailored job offers
            directly to your personal Telegram bot. Or subscribe to the
            auto-apply service, and we'll take care of the applications for you,
            or just enjoy our thousands of vacancies!
          </p>
          <p className="home-title text-7xl font-bold ml-10 tracking-tight">
            AI <span className="text-4xl small-screen-span">careers</span>
          </p>
        </div>
        <ul className="home__cards flex justify-center gap-4 flex-wrap lg:flex-nowrap">
          <li className="home__card">
            <Link to="/board">
              <Button text="10.000+ OFFERS" />
            </Link>
          </li>
          <li className="home__card">
            <Link to="/scout">
              <Button text="VACANCY SCOUT" />
            </Link>
          </li>
          <li className="home__card">
            <Link to="/insights">
              <Button text="JOBS ANALYTICS" />
            </Link>
          </li>
          <li className="home__card">
            <Link to="/voice">
              <Button text="VOICE SEARCH" />
            </Link>
          </li>
          <li className="home__card">
            <Button text="AUTO-APPLY SERVICE" disabled={true} />
          </li>
        </ul>
      </main>
    </div>
  );
};

const Button = ({ text, disabled }) => (
  <button
    disabled={disabled}
    className={`flex flex-col items-center justify-center w-56 h-56 rounded-3xl text-lg font-semibold tracking-tight home-steps-button ${
      disabled ? "cursor-not-allowed opacity-50 pointer-events-none" : ""
    }`}
    style={{
      background:
        "linear-gradient(90deg, #1D1C1F 0%, #1C1C1E 25%, #272432 50%, #322B43 75%)",
    }}
  >
    <span className="text-2xl mb-2">{text.split(" ")[0]}</span>
    <span className="text-2xl">{text.split(" ").slice(1).join(" ")}</span>
    <div className="arrow-container">
      <SvgRightArrow />
    </div>
  </button>
);

export default Home;
