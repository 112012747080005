import React, { createContext, useState, useContext, useEffect } from "react";

const PlanContext = createContext();

export const usePlan = () => useContext(PlanContext);

export const PlanProvider = ({ children }) => {
  const [fromSingleButton, setFromSingleButton] = useState(false);
  const [selectedCountriesFromList, setSelectedCountriesFromList] = useState(
    []
  );
  const [jobsFetched, setJobsFetched] = useState([]);
  const [visibleBlurredJobs, setVisibleBlurredJobs] = useState(0);
  const [pricingPlans, setPricingPlans] = useState([]);

  const [token, setToken] = useState(localStorage.getItem("token")); // Cargar token de localStorage
  const [telegramUser, setTelegramUser] = useState(null);

  const [prefilteredJobs, setPrefilteredJobs] = useState([]);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const fetchedPlans = [
          {
            title: "Free",
            int_ammount: "0",
            features: ["First 30 vacancies"],
            linkText: "Current Plan",
            price: null,
            old_price: null,
            id: 1,
          },
          {
            title: "5$/month",
            int_ammount: "5",
            features: ["Over ten thousand vacancies"],
            linkText: "Choose",
            price: 5,
            old_price: 10,
            id: 2,
          },
          {
            title: "30$/year",
            int_ammount: "30",
            features: ["Over ten thousand vacancies", "Advanced Insights"],
            linkText: "Choose",
            price: 30,
            old_price: 50,
            id: 3,
          },
        ];

        setPricingPlans(fetchedPlans);
      } catch (error) {
        console.error("Error fetching pricing plans:", error);
      }
    };

    fetchPricingPlans();
  }, []);

  const serverUrl = "https://jobs-server-but9.onrender.com";
  // const serverUrl = "http://localhost:5000";

  return (
    <PlanContext.Provider
      value={{
        serverUrl,
        pricingPlans,
        fromSingleButton,
        setFromSingleButton,
        selectedCountriesFromList,
        setSelectedCountriesFromList,
        jobsFetched,
        setJobsFetched,
        visibleBlurredJobs,
        setVisibleBlurredJobs,
        token,
        setToken,
        telegramUser,
        setTelegramUser,
        prefilteredJobs,
        setPrefilteredJobs,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
